// JavaScript Document

// Scripts written by JOrdan @ Webinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faBars as farFaBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faTimes as farFaTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faAngleRight as farFaAngleRight } from "@fortawesome/pro-regular-svg-icons/faAngleRight";

import { faSearch as fasFaSearch } from "@fortawesome/pro-solid-svg-icons/faSearch";
import { faFilePdf as fasFaFilePdf } from "@fortawesome/pro-solid-svg-icons/faFilePdf";
import { faQuestionCircle as fasFaQuestionCircle } from "@fortawesome/pro-solid-svg-icons/faQuestionCircle";

import { faFacebookF as fabFaFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faLinkedinIn as fabFaLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";
import { faTwitter as fabFaTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faYoutube as fabFaYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube";

/**
 * Add regular icons
 */
library.add(farFaBars, farFaTimes, farFaAngleRight);

/**
 * Add solid icons
 */
library.add(fasFaSearch, fasFaFilePdf, fasFaQuestionCircle);

/**
 * Add brands icons
 */
library.add(fabFaFacebookF, fabFaLinkedinIn, fabFaTwitter, fabFaYoutube);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
